(function($) {
    var Controller ={
        standby:function(){
            this.view = {};
            this.view.window = $(window);
            this.view.window.on('load resize', this.imgRespon.bind(this));
            this.view.window.on('load', this.hamburger.bind(this));
            this.view.window.on('load resize', this.homeHeaderFooterScroll.bind(this));
            this.view.window.on('load', this.footerAccordion.bind(this));
            this.view.window.on('load', this.scrollAddClass.bind(this));
            this.view.window.on('load', this.homePageLoaded.bind(this));
        },
        imgRespon:function(){
            if(matchMedia('(min-width: 768px)').matches){
                $('.respon').each(function(){
                    $(this).attr('src',$(this).attr('src').replace('.sp','.pc'));
                });
            }else{
                $('.respon').each(function(){
                    $(this).attr('src',$(this).attr('src').replace('.pc','.sp'));
                });
            }
		},
		hamburger:function(){
			$('.hamburger').on('click',function(){
				$('header').toggleClass('active');
			})
		},
		homeHeaderFooterScroll:function(){
			let currentPage = $('#page').data('page');
			if(currentPage !== "home"){
				return;
			}

			//初期設定
			$('header').addClass('isActive');
			$('footer').addClass('isActive');

			//スクロールイベント
			var controller = new ScrollMagic.Controller();
            var $target = $('#mv');
			var scene = new ScrollMagic.Scene({
				triggerElement : $target.get(0),
				triggerHook : 'onLeave',
				offset: $('#mv').innerHeight() - $('header').innerHeight()
			});
			scene.on('enter', function() {
				$('header').removeClass('isActive')
				$('footer').removeClass('isActive')
			})
			.on('leave', function() {
				$('header').addClass('isActive')
				$('footer').addClass('isActive')

			})
			.addTo(controller);
		},
		footerAccordion:function(){
			$('.accordion').on('click',function(e){
				let W = $(window).innerWidth();
				if(W > 767){
					return;
				}
				if(!$(e.target).parents().is('.accordion-head') && e.target !== $(this).find('dt').get(0)){
					return;
				}
				(e.preventDefault) ? e.preventDefault():e.returnValue=false;

				$(this).toggleClass('active');
				$(this).find('dd').stop().slideToggle();
			});

			const mediaQuery = matchMedia('(min-width:768px)');
			mediaQuery.addListener(onMediaQueryChange);
			function onMediaQueryChange(mediaQuery){
				if(mediaQuery.matches === true){
					$('.accordion').removeClass('active');
					$('.accordion').find('dd').removeAttr('style');
				}
			}
		},
		scrollAddClass:function(){
            var controller = new ScrollMagic.Controller();
            var $targets = $('.scrollmagic');
            $targets.each(function() {
                var $target = $(this);
                var scene = new ScrollMagic.Scene({
                    triggerElement : $target.get(0),
                    triggerHook : 'onEnter',
                    offset : 200
                });
                scene.on('enter', function() {
                    $target.addClass('magic')
                })
                .addTo(controller);
            });
        },
		homePageLoaded:function(){
			let currentPage = $('#page').data('page');
			if(currentPage !== "home"){
				return;
			}

			let visited;
			let cookies = document.cookie.split(';');
			cookies.map(function(value){
				let content = value.split('=');
				if (content[0].trim(" ") === 'visited') {
					visited = content[1];
				};
			})

			if(visited){
				$('#opening').remove();
				$('body').addClass('page-loaded');
				$('body').addClass('opened');
			}else{
				var kigen = 1; //Cookieの期限
				var nowdate = new Date(); //現在の日付データを取得
				nowdate.setTime(nowdate.getTime() + kigen*24*60*60*1000); //1ヶ月後の日付データを作成
				var kigendate = nowdate.toGMTString(); //GMT形式に変換して変数kigendateに格納
				var cookievalue = "visited=true; ";
				var expires = "expires=" + kigendate + "; ";
				var path = "path=/";
				document.cookie = cookievalue + expires + path;

				//ここから処理実行
				var ua = window.navigator.userAgent.toLowerCase();
				//ブラウザのsafariを判定
				var isSafari =  ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1
				//iosかどうかを判定
				var isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
				if(isSafari && !isiOS) {
					$('body').css({'overflow':'hidden'});
				} else {
					$('body').css({
						position: 'fixed',
						width: '100%',
						left: '0',
						top: -1 * this.scrollY,
					});
				}

				$('body,html').animate({scrollTop: 0}, 0);
				$('body').addClass('page-loaded');
				setTimeout(function(){
					$('body').addClass('opened');
					$('body').removeAttr('style');
					$('#opening').remove();
				},4600)
			}
        },
    };
    Controller.standby();
})(jQuery);
